const messagesZh = {
	already_got_an_account: '已经有账户了?',
	register: '注册',
	login: '登录',
	signUp: '注册',
	email: '邮箱',
	getCode: '获取验证码',
	securityCode: '验证码',
	password: '密码',
	confirmPassword: '确认密码',
	please: '请输入',
	pleases: '请选择',
	terms: '条款及条件',
	privacy: '隐私政策',
	Already: '已经注册？',
	and: '和',
	agr1: '建立帐户即代表您同意我们根据 JU-MART 的',
	agr2: '如果您不希望继续收到我们的推广资料，请勾选此框',
	createAccount: '创建一个帐户',
	please_check_the: '请勾选',
	LOG_IN: '登录',
	login_text1: '登录可以加快结算速度，保存购买记录',
	login_text2: '创建您自己的帐户，以便您可以随时查看订单信息和跟踪订单状态',
	Forgot_password: '忘记密码了 ?',
	CREATE_AN_ACCOUNT: '创建一个帐户',
	forgot_password: '忘记了密码?',
	forget_text1: '若要重置密码，请输入电子邮件地址',
	reset_password: '重置密码',
	confirm_the_new_password: '确认新密码',
	proceed_to_Log_In: '继续登录',
	log_in: '登录',
	set_title: '改变地址,语言',
	set_text: '请选择你的送货目的地及货币',
	Countries: '国家',
	Currency: '货币',
	Language: '语言',
	ShippingTo: '运往',
	CONFIRM: '确认',
	Metal: '金属',
	Size: '尺寸',
	Additional_Certification: '附加证书',
	Amount: '数量',
	addCart: '添加购物车',
	CheckOut: '结账',
	Guide: '尺寸选择指南',
	serviceA: '寻找区内的JU-MART线下门店,预约私人的珠宝顾问服务',
	serviceB: '查找',
	reviews: '评论',
	write_a_review: '写评论',
	ViewMore: '查看更多',
	Recommend: '向您推荐',
	Collocation: '搭配组合',
	YouLike: '猜你也喜欢',
	Details: '详情',
	add_success: '加入成功',
	cancel: '取消',
	Inch: '英寸',
	Sort: '排序',
	Filter: '筛选',
	NoLog: '请先登录',
	PartnerDesigners: '合作伙伴设计师',
	CustomDesignStudio: '自定义设计工作室',
	DesignReady: '设计好了',
	love_text: '把你爱的光芒放在命运的红线里让我们的故事与众不同',
	Browse: '浏览',
	best: '我们的畅销货',
	bestsellers: '热销单品',
	diamond_btn: '选购钻石',
	CustomCategories: '自定义分类',
	Start1: '从戒指款式开始',
	Start2: '从钻石开始',
	JM1: 'JU-MART 线下门店',
	JM2: '他们都选择了 JU-MART',
	ContactUs: '联络我们',
	OnlineConsultant: '线上小助手',
	Search: '搜索',
	ShoppingCart: '购物车',
	cartEmpty: '你的购物车是空的',
	cart_text: '当前的购物车有',
	works: '作品',
	ALL: '全部',
	pleaseSelect: '请选择',
	Quantity: '数量',
	Total: '总计',
	OrderInformation: '订单信息',
	Items: '件',
	Subtotal: '小计',
	PromotionCode: '优惠码',
	Promo_Code: '优惠码',
	Promo_Price: '优惠金额',
	promocode: '输入优惠码获得折扣',
	confirm: '确认',
	Confirm: '确认',
	SettleAccounts: '请选择商品后再结账',
	Accountinformation: '账户信息',
	accountOrderA: '登录帐户将用于跟踪您的订单状态',
	accountOrderB: '以下信息将用于跟踪您的订单状态',
	accountOrderC: '订单一概不得运往邮政信箱。',
	accountOrderD: '请提供一个送件时将有人签收的街头地址。',
	Shippinginformation: '配送信息',
	StandardLogistics: '标准物流',
	PickStore: '邮寄到门店',
	Theeditor: '编辑',
	Edit: '编辑',
	Title: '称呼',
	FirstName: '名称',
	LastName: '姓',
	PhoneNumber: '手机号码',
	StateProvince: '州/省',
	PostalCode: '邮编',
	AddressLine1: '详细地址 1',
	AddressLine2: '详细地址 2',
	PaymentMethod: '支付方式',
	Postscript: '附言',
	PostscriptFA: '如果您还有其他要求，请在这里填写',
	SpecialRequirement: '特殊要求',
	PaymentAgrFA: '本人明白并接受有关',
	PaymentAgrFB: '条款与条件',
	PaymentAgrFC: '包括',
	PaymentAgrFD: '付款',
	PaymentAgrFE: '退货政策',
	UnitPrice: '单价',
	ShippingAndInsurance: '运输和保险',
	TaxAndDuty: '税收和关税',
	gender0: '保密',
	gender1: '先生',
	gender2: '女士',
	PaymentType: '请选择支付方式',
	MyDetails: '我的信息',
	OrderStatus: '我的订单',
	MyAppointment: '我的预约',
	Account: '我的账号',
	SignOut: '退出登录',
	Birthday: '生日',
	RelationshipStatus: '感情状态',
	Unmarried: '未婚',
	Married: '已婚',
	Engaged: '订婚',
	anniversary: '你们的结婚纪念日是什么时候?',
	PleaseDate: '请选择日期',
	complete: '完成',
	cancel: '取消',
	YourEmail: '您的邮箱',
	YourPassword: '您的密码',
	YourNewPassword: '您的新密码',
	reconfirm: '再次确认密码',
	NewNumber: '新号码',
	PasswordTips: '输入以下信息来更改密码',
	WishList: '愿望清单',
	have: '你目前有',
	WishLists: '愿望清单',
	listEmpty: '你的愿望清单是空的',
	OrderNo: '订单号',
	Complete: '现货',
	details: '详情',
	loading: '加载中...',
	MyOrder: '我的订单',
	MyApplication: '我的申请',
	ShippingInformation: '配送信息',
	CommodityInformation: '商品信息',
	Create: '创建时间',
	Payment: '支付时间',
	OrderComfirmed: '审核时间',
	ShippedDate: '发货时间',
	CompleteDate: '完成时间',
	Refund: '退款时间',
	LogisticsInformation: '物流信息',
	ConfirmReceipt: '确认收货',
	ProceedToPayment: '继续付款',
	PaymentSuccess: '付款成功',
	PaymentFailure: '付款失败',
	ContinuePurchase: '继续购买',
	return: '返回',
	PaymentFA: '我们的客户服务团队已经收到了您的订单，目前正在审核。',
	PaymentFB: '付款失败，请重新付款。',
	AppointmentDetails: '预约详情',
	StoreService: '门店服务',
	BusinessHours: '营业时间',
	Change: '改变',
	shopFA: '找一家离我近的商店',
	shopFB: '预约',
	Location: '位置',
	CityRegion: '城市区域',
	SelectStore: '选择门店',
	VisitingAppointment: '去预约',
	appointmentFA: '选择您喜欢的商店，在友好的员工的支持下享受定制设计体验。填妥表格并递交，我们会以电话或电邮与您联络确认。',
	appointmentFB: '你想去哪家店',
	appointmentFC: '你想要的联系方式',
	appointmentFD: '请详细说明我们可以如何帮助你。',
	appointmentFE: '你已经有想法了吗?(可选)',
	appointmentFF: '我们需要收集及处理你的个人资料，以回应你的要求。如果您使用本服务，您已阅读并接受本',
	appointmentFG: '我们会与您联系',
	appointmentFH: '我们已收到您的请求,并将在48小时内与您联系,安排店内预约。',
	appointmentFI: '您的选择',
	appointmentFJ: '您在 “{count}” 的预约被批准。具体情况如下:',
	appointmentFK: '勾选此方框，即表示我同意本',
	appointmentFL: ',并同意将所提供的信息用于JU-MART。',
	PrivacyTerms: '隐私条款。',
	MyDesign: '我的设计',
	Choose: '选择',
	AppointmentTime: '预约日期及时间',
	HomePage: '首页',
	Purpose: '目的',
	// AppointmentTime: '预约时间',
	IChooseThe: '我选择的',
	CancelSuccess: '取消成功',
	Category: '分类',
	Clear: '清空',
	Found: '发现',
	results: '结果',
	PleaseKeywords: '请输入关键字',
	recommend: '推荐',
	SearchFA: '用自己的方式设计专属的订婚戒指',
	goto: '去',
	prompt: '提示',
	promptFA: '您还没有登录。您想登录吗？',
	DeleteRecord: '删除记录?',
	Share: '分享',
	resultsFA: '"{count}" 没有找到任何结果',
	resultsFB: '请重新搜索',
	resultsFC: '没有找到任何结果',
	GuideFA: '拿一根不可拉伸的绳子、丝带、线或一张薄纸。把它舒适地绕在无名指或其他手指上，靠近指关节',
	GuideFB: '仔细地用钢笔在琴弦重叠的地方做记号。为了达到最好的准确性，尽可能做最小的标记',
	GuideFC: '打开标签的长度。用尺子测量毫米或英寸。在下面输入你的测量值',
	GuideFE: '输入你的尺寸',
	GuideFF: '您想把测量好的尺寸存到您的账户中吗?',
	GuideFG: '是的，我想保存它',
	ScaleUnits: '标度单位',
	Start: '开始',
	MeasureResult: '测量结果',
	MeasureResultFB: '您的尺码是',
	send: '独特而有品位的物品让你送出你的，送给你的',
	DataEmpty: '暂无数据',
	RefundFA: '申请退货/退款',
	RefundFB: '收到物品后(7)天内生效，定制物品除外。提交您的申请，我们的客户代表将回复您的后续行动。',
	RefundFC: '服务类型',
	RefundFD: '退换货原因',
	RefundFE: '详细描述',
	RefundFF: '图片资料',
	RefundFG: '为了帮助我们更好的解决这个问题，请上传图片',
	RefundFH: '申请退款',
	RefundFI: '申请时间',
	RefundFJ: '退回类型',
	RefundFK: '退款金额',
	refund: '(退款)',
	Remark: '备注',
	submit: '提交',
	salesAfterFA: '在审查中',
	salesAfterFB: '我们的客户服务团队已经收到您的退款请求，目前正在为您审查它',
	salesAfterFC: '退款被拒绝',
	salesAfterFD: '很抱歉，您的请求被拒绝了。如果您有任何问题，请联系我们的客服代表。我们希望很快再见到您！',
	salesAfterFE: '退款成功',
	salesAfterFF: '您的退款已被批淮，所要求的金额已通过您原来的支付方式返回给您。请注意查收',
	salesAfterFG: '缺货',
	salesAfterFH: '我们非常抱歉。每颗砖石都是独一无二,您选择的钻石已经断货,我们已经选择了几颗价格相识,4C的钻石给您替换,仍然不能满足您的要求,我们将通过您原来的付款方式将金额原路退回。谢谢您的理解,希望很快能见到您!',
	salesAfterFI: '商家回复：',
	salesAfterFJ: '审查通过',
	salesAfterFK: '审查通过,等待商家退款',
	salesAfterFL: '退款已取消',
	salesAfterFM: '退货不接受',
	salesAfterFN: '可能商品影响第二次销售',
	PleaseSize: '请选择尺寸大小',
	arm: '主件',
	accessories: '配件',
	styles: '款式',
	material: '材质',
	diamond: '钻石',
	Engraving: '刻字',
	EngravingFA: '最多12个字符,6个汉字',
	EngravingFB: '该商品不支持雕刻',
	EngravingFC: '字母数字组合,长度最多12个字符',
	Cost: '成本',
	DesignFolder: '设计文件夹',
	buy: '购买',
	Setting: '设置',
	Diamond: '钻石',
	ProductFailure: '商品失效',
	ProductDetails: '产品描述',
	Latest: '最新',
	Price: '价格',
	Carat: '克拉',
	Colour: '颜色',
	Cut: '切工',
	Clarity: '净度',
	Polish: '抛光',
	Symmetry: '对称',
	Fluor: '荧光',
	replace: '替换',
	ClearFilters: '清空筛选',
	points: '分',
	Choice: '选择',
	NoComments: '暂无评论',
	Score: '得分',
	PleaseContent: '请输入内容',
	PostAnonymously: '匿名帖子',
	PickupCode: '提取码',
	NoDiamonds: '没有找到符合条件的钻石',
	SubmitSuccess: '提交成功',
	ReviewFA: '你已评论',
	Edit: '编辑',
	AddCart: '添加到购物车',
	SaveName: '保存的名字',
	SavePhone: '请输入手机号',
	SaveDesign: '保存到我的设计',
	Existing: '现成商品',
	Custom: '定制商品',
	Select: '选择',
	EssentialInformation: '基本信息',
	MyDesign: '我的设计',
	designFA: '你目前已经设计了',
	designFB: '件作品',
	designFC: "你还没有开始你的设计。",
	ViewAll: '查看全部',
	CompareDesigns: '比较设计',
	Comparison: '比较',
	WEACCEPT: '我们接受以下付款方式',
	or: '或',
	SeeMore: '查看更多',
	DiscoverMore: '发现更多的',
	HomeFA: '第一次订购可享受10%的优惠',
	HomeFB: '第一个体验我们的3D定制和虚拟试穿',
	HomeFC: "JU-MART最好的卖家",
	HomeFD: '全球免运费',
	pleaseFA: '请选择称呼',
	pleaseFB: '请输入名称',
	pleaseFC: '请输入姓',
	pleaseFD: '请输入邮箱',
	pleaseFE: '请输入手机号码',
	pleaseFF: '请选择预约时间',
	pleaseFG: '请勾选隐私条款',
	Customization: '体验定制化功能',
	Shopping: '回到购物',
	RefundOrReturn: '退款/退货',
	uploading: '上传中...',
	done: '上传完成',
	changeFA: '换指',
	changeFB: '换手',
	photoFA: '拍照',
	photoFB: '重拍',
	flip: '翻转',
	mirror: '镜像',
	share: '分享',
	back: '收起',
	TryOn1: '试戴',
	TryOn2: '我要试戴',
	TouchFA: '横向-旋转',
	TouchFB: '纵向-移动',
	TouchFC: '双指-缩放',
	shareFB: '长按分享',
	CookiePolicy: 'Cookie 政策',
	TermsOfUse: '使用条款',
	taxes: '**** 不含税 ****',
	// subscribeFA: '获取独家报价和新闻',
	subscribeFA: '获得专享优惠和新消息',
	subscribeFB: '你的电子邮件',
	subscribeFC: '登记',
	subscribeFD: '感谢您的订阅！',
	Cancel: '取消',
	Search: '搜索',
	EditSuccess: '编辑成功',
	Hi: '嗨',
	ChooseFa: '选择这颗钻石',
	ChooseFb: '请选择钻石',
	Certification: '资质证书',
	ReportNumber: '编号',
	Shape: '形状',
	Depth: '深度',
	Table: 'Table %',
	Measurements: '测量值',
	StockNumber: '库存编号',
	Location: '地址',
	StartWithDiamonds: '从钻石开始',
	SETTING: '设置',
	DiamondGemstone: '钻石/宝石',
	RecentlyViewed: '最近浏览',
	NotFun: '产品不存在或已下架',
	low: '最低',
	high: '最高',
	BackHome: '返回首页',




	language: '语言',
	currency: '货币',
	sort: '排序',
	filter: '过滤',
	description: '描述',
	cart: '购物车',
	subtotal: '小计',
	first_name: '名',
	last_name: '姓',
	telephone: '电话',
	street: '街道',
	country: '国家',
	state: '省',
	city: '城市',
	password: '密码',
	account: '账户',
	home: '首页',
	sign_in: '登录',
	logout: '退出登录',
	name: '名字',
	address: '地址',
	date: '日期',
	submit: '提交',
	review: '评论',
	rate: '评星',
	name: '姓名',
	gem: '宝石',
	price: '价格',
	Appointment: '我的预约',
	Account: '账户信息',

	Waiting: '等待付款',
	WaitingFA: '您的订单已创建,请继续完成付款;如未能在60分钟内付款,订单将自动取消。',
	WaitingFB: '支付处理中',
	WaitingFC: '付款成功',
	WaitingFD: '欺诈',
	InReview: '在审查中',
	InReviewFA: '我们的客户服务团队已经收到了您的订单，目前正在为您审查中。',
	InReviewFB: '请耐心等待',
	wSend: '订单已确认',
	wSendFA: '客服已经确认您的订单。我们会尽快安排订单处理并发货给您。',
	wGoods: '您的订单已经发货',
	wGoodsFA: '请检查发货人的跟踪号查看物流状态。',
	wreceived: '您的订单已收货',
	wEnd: '您的订单完成了',
	wEndFA: '签署并接收订单。感谢您在JU-MART购物,希望您喜欢您的新珠宝！请给我们您的意见和建议。我们希望很快再见到您！',
	wExpired: '您的订单已取消',
	wExpiredFA: '您的订单已取消',
	wExpiredFB: '你要走了，我们很难过!您的付款逾期，订单被取消。我们期待很快再见到你!',
	wExpiredFC: '您的付款过期了',
}
export default messagesZh